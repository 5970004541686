var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cusCover" },
    [
      _vm.imgInfo.src
        ? _c("div", { staticStyle: { width: "100%", height: "390px" } }, [
            _c("img", {
              ref: "img",
              staticClass: "cropperBox",
              attrs: { src: _vm.imgInfo.src },
              on: {
                load: function ($event) {
                  return _vm.imgLoad($event)
                },
              },
            }),
          ])
        : _c(
            "a-upload-dragger",
            {
              staticClass: "uploadBox",
              attrs: {
                name: "file",
                action: "#",
                "before-upload": _vm.beforeUpload,
                "show-upload-list": false,
              },
            },
            [
              _c(
                "p",
                { staticClass: "ant-upload-drag-icon" },
                [_c("a-icon", { attrs: { type: "inbox" } })],
                1
              ),
              _c("p", { staticClass: "ant-upload-text" }, [
                _vm._v(" 点击或拖拽文件到此区域上传海报 "),
              ]),
              _c("p", { staticClass: "ant-upload-hint" }, [
                _vm._v(
                  " 建议尺寸：" +
                    _vm._s(_vm.imgSize.width) +
                    "*" +
                    _vm._s(_vm.imgSize.height) +
                    " "
                ),
              ]),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }